window.bootstrap = require('bootstrap');

window.addEventListener('DOMContentLoaded', event => {
    // download vpn config

    var downloadBtnList = [].slice.call(document.querySelectorAll('[data-download-ovpn]'));
    downloadBtnList.map(function (downloadTriggerEl) {
        downloadTriggerEl.addEventListener('click', e => {
            e.preventDefault();

            let textToWrite = document.getElementById(downloadTriggerEl.dataset.downloadOvpn).value;
            let textFileAsBlob = new Blob([textToWrite], {type: 'text/plain'});
            let fileNameToSaveAs = downloadTriggerEl.dataset.ovpnFilename;

            let downloadLink = document.createElement("a");
            downloadLink.download = fileNameToSaveAs;
            downloadLink.innerHTML = "Download File";
            if (window.webkitURL != null) {
                // Chrome allows the link to be clicked without actually adding it to the DOM.
                downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
            } else {
                // Firefox requires the link to be added to the DOM before it can be clicked.
                downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
                downloadLink.onclick = function (event) {
                    document.body.removeChild(event.target);
                };
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
            }

            downloadLink.click();

            return false;
        });
    });

    let credentialsModal = document.getElementById('modalShowCredentials')

    if (credentialsModal) {
        credentialsModal.addEventListener('show.bs.modal', function (event) {
            let button = event.relatedTarget;
            let clientId = button.dataset.client_id;
            let clientSecret = button.dataset.client_secret;

            let fldClientId = credentialsModal.querySelector('.field-client-id');
            let fldClientSecret = credentialsModal.querySelector('.field-client-secret');

            fldClientId.textContent = clientId;
            fldClientSecret.textContent = clientSecret;
        });
    }

    // Enable tooltips globally
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    // Enable popovers globally
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
    });

    const stickyNav = document.body.querySelector('#stickyNav');
    if (stickyNav) {
        new bootstrap.ScrollSpy(document.body, {
            target: '#stickyNav',
            offset: 82,
        });
    }

    //javascript:
    //
    // document.querySelectorAll(".js-show-credentials").forEach(btn =>
    //     btn.addEventListener("click", (event) => {
    //         event.preventDefault();
    //
    //         let myModal = new bootstrap.Modal(document.getElementById('modalShowCredentials'));
    //
    //         myModal.show()
    //     })
    // )

    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            document.body.classList.toggle('sidenav-toggled');
        }
        sidebarToggle.addEventListener('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sidenav-toggled'));
        });
    }

    // Close side navigation when width < LG
    const sidenavContent = document.body.querySelector('#layoutSidenav_content');
    if (sidenavContent) {
        sidenavContent.addEventListener('click', event => {
            const BOOTSTRAP_LG_WIDTH = 992;
            if (window.innerWidth >= 992) {
                return;
            }
            if (document.body.classList.contains("sidenav-toggled")) {
                document.body.classList.toggle("sidenav-toggled");
            }
        });
    }

    // Add active state to sidbar nav links
    let activatedPath = window.location.pathname;
    let activatedUrl = window.location.href;

    const targetAnchors = document.body.querySelectorAll('[href="' + activatedPath + '"].nav-link, [href="' + activatedUrl + '"].nav-link');

    targetAnchors.forEach(targetAnchor => {
        let parentNode = targetAnchor.parentNode;
        while (parentNode !== null && parentNode !== document.documentElement) {
            if (parentNode.classList.contains('collapse')) {
                parentNode.classList.add('show');
                const parentNavLink = document.body.querySelector(
                    '[data-bs-target="#' + parentNode.id + '"]'
                );
                parentNavLink.classList.remove('collapsed');
                parentNavLink.classList.add('active');
            }
            parentNode = parentNode.parentNode;
        }
        targetAnchor.classList.add('active');
    });
});


// dodwanie pol formularzy
window.addEventListener('DOMContentLoaded', event => {
    const addFormToCollection = (e) => {
        e.preventDefault();
        const collectionHolder = document.getElementById(e.target.dataset.target);
        const item = document.createElement('div');
        if (!collectionHolder.dataset.index) {
            collectionHolder.dataset.index = collectionHolder.children.length;
        }

        item.innerHTML = collectionHolder
            .dataset
            .prototype
            .replace(
                /__name__/g,
                collectionHolder.dataset.index
            );

        item.firstChild.classList.add('d-flex');
        addDeleteButton(item.firstChild);

        collectionHolder.appendChild(item.firstChild);

        collectionHolder.dataset.index++;
    };

    const addDeleteButton = (row) => {
        row.classList.add('d-flex');
        let deleteItemButton = document.createElement('button');
        deleteItemButton.classList.add('delete_item_link', 'ms-4', 'btn', 'btn-danger');
        deleteItemButton.innerText = 'Usuń';

        deleteItemButton.addEventListener('click', e => {
            e.preventDefault();
            e.target.parentNode.remove();
        });

        row.appendChild(deleteItemButton);
    }

    document.querySelectorAll('[data-prototype]').forEach(el => {
        let addItemButton = document.createElement('button');
        addItemButton.dataset.target = el.id;
        addItemButton.classList.add('btn', 'btn-secondary');
        addItemButton.innerText = 'Dodaj';

        el.parentNode.insertBefore(addItemButton, el.nextSibling);

        addItemButton.addEventListener("click", addFormToCollection);

        el.childNodes.forEach(addDeleteButton);
    });


});


import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth'
import plLocale from '@fullcalendar/core/locales/pl';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import 'bootstrap-icons/font/bootstrap-icons.css';

window.addEventListener('DOMContentLoaded', event => {
    let calendarEl = document.getElementById('calendar-holder');
    if (calendarEl) {
        let calendar = new Calendar(calendarEl, {
            dayMaxEventRows: 99,
            dayMaxEvents: false,
            resourceAreaHeaderContent: 'Pracownik',
            refetchResourcesOnNavigate: true,
            resources: calendarEl.dataset.resourcesUrl,
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            height: 'auto',
            themeSystem: 'bootstrap5',
            weekends: false,
            headerToolbar: {center: 'dayGridMonth,multiMonthYear,resourceTimelineMonth,resourceTimelineYear'},
            plugins: [bootstrap5Plugin, dayGridPlugin, multiMonthPlugin, listPlugin, resourceTimelinePlugin],
            initialView: 'dayGridMonth',
            locale: plLocale,
            eventDidMount: function (info) {
                let dateStr = info.event.extendedProps.startStr;
                if (info.event.extendedProps.endStr) {
                    dateStr = info.event.extendedProps.startStr + ' - ' + info.event.extendedProps.endStr;
                }

                let config = {
                    html: true,
                    title: '<p><strong>' + info.event.title + '</strong><br />' + info.event.extendedProps.type + '<br />' + dateStr + '</p>' + info.event.extendedProps.status,
                    placement: 'top',
                    trigger: 'hover',
                    container: 'body'
                };

                if (info.event.extendedProps.holiday) {
                    config.title = info.event.extendedProps.holiday;
                }
                let tooltip = new bootstrap.Tooltip(info.el, config);
            },
            eventSources: [
                {
                    url: calendarEl.dataset.url,
                    method: "POST",
                    extraParams: {
                        filters: JSON.stringify({})
                    },
                    failure: () => {
                        alert("Problem z pobieraniem urlopów. Spróbuj ponownie później.");
                    },
                },
            ],
        });

        calendar.render();
    }
});
