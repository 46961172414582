document.querySelector('body').addEventListener('click', function(e) {
    let el = e.target.closest('a, button');

    if(el && el.classList.contains('js-confirm')) {
        if(confirm(el.dataset.confirm)) {
            return true;
        }
        e.preventDefault();
        return false;
    }
}, false);
